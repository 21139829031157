<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>Leads</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <!-- <v-dialog
        v-if="selected"
        persistent
        scrollable
        v-model="showEdit"
        max-width="600px"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.username"
                      v-bind:label="$t('salesforce.UserName')"
                      required
                      :rules="usernameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('salesforce.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.surname"
                      v-bind:label="$t('salesforce.SurName')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.residence"
                      v-bind:label="$t('salesforce.ResidenceCity')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="selected.provincia"
                      label="Provincia"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.email"
                      v-bind:label="$t('salesforce.email')"
                      required
                      :rules="selected.status === 'inactive' ? [] : emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.phone"
                      v-bind:label="$t('salesforce.phone')"
                      required
                      :rules="lengthRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.phone_company"
                      v-bind:label="$t('salesforce.phone_company')"
                      required
                      :rules="lengthRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.dni"
                      v-bind:label="$t('salesforce.dni')"
                      required
                      :rules="[...requiredRules, ...lengthRules]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.brands"
                      v-bind:label="$t('project')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_projects"
                      :loading="isProjectsSearching"
                      :items="filtered_projects"
                      hide-no-data
                      filled
                      multiple
                      clearable
                      @change="search_survey_val('')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.discount_km"
                      v-bind:label="$t('discount_km')"
                      :type="'number'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.role"
                      v-bind:label="$t('salesforce.user_type')"
                      :items="user_type"
                      filled
                      required
                      :rules="requiredRules"
                      @change="update_user_type"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.department"
                      :label="$t('salesforce.department')"
                      :items="departments"
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="selected.role === 'brand'">
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-autocomplete
                      v-model="selected.brandSurveys"
                      label="Encuestas"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_surveys"
                      :loading="isSearching['surveys']"
                      :items="filtered_surveys"
                      hide-no-data
                      filled
                      multiple
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6"
                    ><v-autocomplete
                      v-model="selected.anadimos_exports"
                      :label="`Añadimos Exports`"
                      :items="['SI', 'NO']"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete
                  ></v-col>
                </v-row>
                <v-row v-if="selected.role === 'brand'">
                  <v-col cols="12" sm="12" md="6"
                    ><v-autocomplete
                      v-model="selected.isEdit"
                      :label="`es editar`"
                      :items="['Editar', 'No editar']"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="12" sm="12" md="6"
                    ><v-autocomplete
                      v-model="selected.landingPage"
                      :label="`Página de destino`"
                      :items="landingPages"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.parent_id"
                      v-bind:label="$t('salesforce.Parent')"
                      :items="user_list"
                      item-text="username"
                      item-value="id"
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.routes"
                      :label="$t('route')"
                      :items="routes"
                      item-text="name"
                      item-value="id"
                      filled
                      clearable
                      :required="isRequiredRoute"
                      :rules="isRequiredRoute ? requiredRules : []"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.companyCode"
                      :label="$t('Company')"
                      :items="companies"
                      item-text="name"
                      item-value="id"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.dateEndOfVacation"
                      v-bind:label="`Fecha Fin Vacaciones`"
                      clearable
                      required
                      :rules="requiredRules"
                      @change="onChangeDateEndOfVacation"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-btn
                          color="primary"
                          class="ml-2 white--text"
                          @click="addNewDateEntry"
                        >
                          <v-icon dark>mdi-plus</v-icon> añadir
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <table
                          class="table b-table table-striped table-bordered table-sm b-table-stacked-sm routepage_ps_table mb-0"
                        >
                          <colgroup>
                            <col width="40%" />
                            <col width="40%" />
                            <col width="20%" />
                          </colgroup>
                          <tr>
                            <th class="text-center">
                              {{ $t("brands.start_date") }}
                            </th>
                            <th class="text-center">
                              {{ $t("brands.end_date") }}
                            </th>
                            <th class="text-center"></th>
                          </tr>
                          <template
                            v-if="selected.user_date_entries.length > 0"
                          >
                            <tr
                              v-for="(date_item,
                              date_item_key) in selected.user_date_entries"
                              :key="`date_table_row_${date_item_key}`"
                            >
                              <td class="text-center">
                                <v-menu
                                  ref="start_date_menu"
                                  v-model="select_start_date_menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                  v-if="
                                    isLastUserDateEntry(
                                      selected.user_date_entries,
                                      date_item_key
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="
                                        selected.user_date_entries[
                                          date_item_key
                                        ].userStartDate
                                      "
                                      v-bind:label="$t('brands.start_date')"
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      v-on="on"
                                      readonly
                                      required
                                      :rules="
                                        selected.isSelectableDates
                                          ? requiredRules
                                          : selectableRules
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="
                                      selected.user_date_entries[date_item_key]
                                        .userStartDate
                                    "
                                    no-title
                                    @input="select_start_date_menu = false"
                                    :first-day-of-week="1"
                                    @change="onChangeDates(date_item_key)"
                                  ></v-date-picker>
                                </v-menu>
                                <span v-else>
                                  {{ date_item?.userStartDate }}
                                </span>
                              </td>
                              <td class="text-center">
                                <v-menu
                                  ref="end_date_menu"
                                  v-model="select_end_date_menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                  v-if="
                                    isLastUserDateEntry(
                                      selected.user_date_entries,
                                      date_item_key
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="
                                        selected.user_date_entries[
                                          date_item_key
                                        ].userEndDate
                                      "
                                      v-bind:label="$t('brands.end_date')"
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      v-on="on"
                                      readonly
                                      required
                                      :rules="
                                        selected.isSelectableDates
                                          ? requiredRules
                                          : selectableRules
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="
                                      selected.user_date_entries[date_item_key]
                                        .userEndDate
                                    "
                                    no-title
                                    scrollable
                                    @input="select_end_date_menu = false"
                                    :first-day-of-week="1"
                                    @change="onChangeDates(date_item_key)"
                                  ></v-date-picker>
                                </v-menu>
                                <span v-else>
                                  {{ date_item?.userEndDate }}
                                </span>
                              </td>
                              <td class="text-center">
                                <v-icon
                                  color="red"
                                  v-if="
                                    isLastUserDateEntry(
                                      selected.user_date_entries,
                                      date_item_key
                                    ) && selected.user_date_entries.length > 1
                                  "
                                  @click="removeLastDateEntry()"
                                >
                                  mdi-delete
                                </v-icon>
                              </td>
                            </tr>
                          </template>
                        </table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.km_in_liquidation"
                      v-bind:label="$t('km_in_liquidations')"
                      :items="km_in_liquidations"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.liquidationType"
                      v-bind:label="$t('Liquidation Type')"
                      :items="liquidationTypes"
                      item-text="label"
                      item-value="value"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="d-flex justify-center"
                  >
                    <v-radio-group
                      v-model="selected.status"
                      row
                      required
                      :rules="requiredRules"
                    >
                      <v-radio label="Active" value="active"></v-radio>
                      <v-radio label="Inactive" value="inactive"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="selected.id !== undefined">
                  <v-row
                    v-for="(entry,
                    index) in selected.additional_static_pendingholidays"
                    v-bind:key="index"
                    align="center"
                  >
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        v-model="entry.appliedYear"
                        v-bind:label="$t('Year')"
                        clearable
                        :rules="yearRules"
                        @input="onChangeAdditionalPendingHolidayYear"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        v-model="entry.pendingholidays"
                        v-bind:label="$t('holidaypending')"
                        :type="'number'"
                        clearable
                        :rules="additionalHolidayRules"
                        @input="onChangeAdditionalPendingHolidayValue"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      v-if="
                        index !=
                          selected.additional_static_pendingholidays.length - 1
                      "
                      ><v-icon color="red" @click="deletePendingHoliday(index)">
                        mdi-delete
                      </v-icon></v-col
                    >
                  </v-row>
                </v-row>
                <v-row v-if="selected.id !== undefined">
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-btn
                      color="primary"
                      class="ml-2 white--text"
                      @click="addNewPendingHoliday"
                    >
                      <v-icon dark>mdi-plus</v-icon> añadir
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="isSaving"
                @click="onSaveClick(selected)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog> -->

      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col cols="12" sm="5" md="5">
            <v-btn
              v-if="!isManager"
              color="primary"
              dark
              class="mb-2"
              @click="onItemEdit"
            >
              <v-icon dark>mdi-plus</v-icon> añadir
            </v-btn>
            <v-btn
              v-if="!isManager"
              color="success"
              dark
              class="mb-2 ml-2"
              :loading="isSelecting"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonText }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn color="warning" dark class="mb-2 ml-2">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              v-bind:label="`Ult. Contacto`"
              v-bind:placeholder="$t('start_typing_to_search')"
              item-text="fullname"
              item-value="id"
              hide-no-data
              filled
              multiple
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              v-bind:label="`Estado`"
              v-bind:placeholder="$t('start_typing_to_search')"
              item-text="name"
              item-value="id"
              hide-no-data
              filled
              multiple
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="leads_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="leads_data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1000"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="total_leads"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";

export default {
  name: "Leads",
  data: function() {
    return {
      total_leads: 0,
      leads_data: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: ["role"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          let className = "";
          if (column.field !== "status") {
            if (rowIndex % 2 !== 0) {
              className = "table-body-cell-even";
            } else {
              className = "table-body-cell-odd";
            }
          } else {
            if (row.status === "activo") className += " status-active";
            else className += " status-inactive";
          }
          if (column.field === "tipo") {
            if (row.tipo === "Cliente") className += " tipo-cliente";
            else className += " tipo-lead";
          }
          return className;
        }
      },
      filterCriteria: {
        role: {}
      },
      showEmpty: false,

      loading: true,
      options: {},
      editedIndex: -1,
      selected: null,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      selectableRules: [
        v => !!v || "Required",
        v => "Las fechas de varias inscripciones no deben superponerse."
      ],
      usernameRules: [
        v => !!v || "Required",
        v => !v || v.length < 10 || "¡Desborde la longitud!"
      ],
      lengthRules: [v => !v || v.length < 10 || "¡Desborde la longitud!"],
      emailRules: [
        v => !!v || "Required",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      yearRules: [v => !v || v > 1970 || "Incorrect Year !"],
      additionalHolidayRules: [
        v => !v || (v >= -22 && v < 23) || "Incorrect Pending Holiday !"
      ],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      showEdit: false,
      dialogDelete: false,
      deleteItemId: -1,
      user_type: [],
      user_status: ["active", "inactive"],
      km_in_liquidations: ["Yes", "No"],
      routes: [],
      liquidationTypes: [],

      parent_items: [],
      isParentLoading: false,
      search: null,
      user_list: [],
      select_start_date_menu: false,
      select_end_date_menu: false,

      search_projects: null,
      isProjectsSearching: false,
      filtered_projects: [],
      isSaving: false,

      filtered_surveys: [],
      search_surveys: null,
      isSearching: {},

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /************************************* Loading Spinner Overlay ************************************/
      isLoadingSpinner: false
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  computed: {
    ...mapGetters("auth", ["isManager"]),
    isRequiredRoute() {
      let ret = false;
      if (this.selected && this.selected?.role && this.selected.role == "gpv") {
        ret = true;
      }
      return ret;
    },
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    col_edit() {
      return {
        key: "q",
        title: this.$t("salesforce.Edit"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
            </div>
          );
        }
      };
    },
    col_ir() {
      return {
        key: "IR",
        title: "IR",
        field: "IR",
        align: "center",
        fixed: "right",
        width: 100,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="blue darken-1"
                text
                on-click={() => this.onItemGo(row)}
              >
                <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    columns() {
      let common_cols = [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 70,
          sortBy: "ASC",
          fixed: "left"
        },
        {
          key: "empresa",
          title: "Nombre empresa",
          field: "empresa",
          align: "left",
          width: 200,
          sortBy: "",
          fixed: "left"
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["username"]}
          //           on-input={value =>
          //             (this.filterCriteria["username"] = value)
          //           }
          //           placeholder="Search username"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "username")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   }
          // }
        },
        {
          key: "contacto",
          title: "Contacto",
          field: "contacto",
          align: "left",
          width: 150,
          sortBy: ""
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["name"]}
          //           on-input={value => (this.filterCriteria["name"] = value)}
          //           placeholder="Search name"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "name")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   }
          // }
        },
        {
          key: "direccion",
          title: "Direccion",
          field: "direccion",
          align: "left",
          width: 250,
          sortBy: ""
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["surname"]}
          //           on-input={value => (this.filterCriteria["surname"] = value)}
          //           placeholder="Search surname"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "surname")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   }
          // }
        },
        {
          key: "mail",
          title: "Mail",
          field: "mail",
          align: "left",
          width: 200,
          sortBy: ""
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["email"]}
          //           on-input={value => (this.filterCriteria["email"] = value)}
          //           placeholder="Search email"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "email")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   }
          // }
        },
        {
          key: "telefono",
          title: "Telefono",
          field: "telefono",
          align: "left",
          width: 200,
          sortBy: "DESC"
        },
        {
          key: "comercial",
          title: "Comercial",
          field: "comercial",
          align: "left",
          width: 130,
          sortBy: "ASC"
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["domainName"]}
          //           on-input={value =>
          //             (this.filterCriteria["domainName"] = value)
          //           }
          //           placeholder="Search Domain Name"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "domainName")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   }
          // }
        },
        {
          key: "ultimo_contacto",
          title: "Ultimo Contacto",
          field: "ultimo_contacto",
          align: "left",
          width: 200,
          sortBy: ""
        },
        {
          key: "status",
          title: "Estado",
          field: "status",
          align: "left",
          width: 100,
          sortBy: ""
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["phone"]}
          //           on-input={value => (this.filterCriteria["phone"] = value)}
          //           placeholder="Search phone"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "phone")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   }
          // }
        },
        {
          key: "oportunidad",
          title: "Oportunidad",
          field: "oportunidad",
          align: "left",
          width: 150,
          sortBy: ""
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["phone_company"]}
          //           on-input={value =>
          //             (this.filterCriteria["phone_company"] = value)
          //           }
          //           placeholder="Search phone company"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() =>
          //               this.searchCancel(closeFn, "phone_company")
          //             }
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   }
          // }
        },
        {
          key: "tipo",
          title: "Tipo",
          field: "tipo",
          align: "left",
          width: 100,
          sortBy: ""
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["dni"]}
          //           on-input={value => (this.filterCriteria["dni"] = value)}
          //           placeholder="Search DNI"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchCancel(closeFn, "dni")}
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   }
          // }
        }
      ];
      if (!this.isManager) {
        return [...common_cols, this.col_ir, this.col_edit];
      } else {
        return [...common_cols];
      }
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    cancelFilter() {
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        this.filterCriteria[key] = null;
      }
      this.getDataFromApi();
    },

    changeFilter() {
      this.getDataFromApi();
    },

    getQueryParams(options) {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "start_date" || key === "end_date") {
            let convert_value = {
              from: "",
              to: ""
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = moment(value["from"], "YYYY-MM-DD")
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ");
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = moment(value["to"], "YYYY-MM-DD")
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ");
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.loading = true;
      this.showloadingspinner();
      let query_params_string = this.getQueryParams(this.options);
      let fetch_url = "salesforce/users";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      // const resp = await ApiService.get(fetch_url);

      let leads_data = [
        {
          id: 423,
          empresa: "Cuatrecasas",
          contacto: "Javier, Fernandez",
          direccion: "Av. Diagonal, 191, 08018 Barcelona",
          mail: "barcelona@cuatrecas.com",
          comercial: "Ignacio",
          telefono: "+34 932 905 492",
          ultimo_contacto: "14/03/2025",
          status: "activo",
          oportunidad: "206€",
          tipo: "Cliente"
        },
        {
          id: 422,
          empresa: "Carrigues",
          contacto: "Marta Lopez",
          direccion: "Avinguda Diagonal, 654, 08034 Barcelona",
          mail: "barcelona@garrigues.com",
          telefono: "+34 93 253 3700",
          comercial: "Ignacio",
          ultimo_contacto: "14/03/2025",
          status: "inactivo",
          oportunidad: "405€",
          tipo: "Lead"
        },
        {
          id: 422,
          empresa: "Carrigues",
          contacto: "Marta Lopez",
          direccion: "Avinguda Diagonal, 654, 08034 Barcelona",
          mail: "barcelona@garrigues.com",
          telefono: "+34 93 253 3700",
          comercial: "Ignacio",
          ultimo_contacto: "14/03/2025",
          status: "inactivo",
          oportunidad: "405€",
          tipo: "Cliente"
        },
        {
          id: 421,
          empresa: "Fruhbeck Abogados",
          contacto: "Sergi Gomez",
          direccion: "C/de Balmes, 368, 08006 Barcelona",
          mail: "barcelona@fruhbeck.com",
          telefono: "+34 932 541 070",
          comercial: "Alejandra",
          ultimo_contacto: "12/03/2025",
          status: "activo",
          oportunidad: "320€",
          tipo: "Lead"
        },
        {
          id: 420,
          empresa: "Abogados Asensio & Prats",
          contacto: "Laura Marti",
          direccion: "C/ Manigua 21-23, 3B, 08027 Barcelona",
          mail: "asensioprats@gmail.com",
          telefono: "934 084 047",
          comercial: "Alejandra",
          ultimo_contacto: "12/03/2025",
          status: "activo",
          oportunidad: "790€",
          tipo: "Lead"
        },
        {
          id: 419,
          empresa: "Manubens",
          contacto: "Aleix Sanchez",
          direccion: "Av. Diaonal, 682 - Planta 3, 08034 Barcelona",
          mail: "info.barcelona@manubens.com",
          telefono: "+34 93 206 35 90",
          comercial: "Victor",
          ultimo_contacto: "11/03/2025",
          status: "activo",
          oportunidad: "250€",
          tipo: "Lead"
        },
        {
          id: 418,
          empresa: "Duran & Duran Abogados",
          contacto: "Natalia Riera",
          direccion: "Avda. Diagnoal, 472, Esc.A, A Planta, 08006 Barcelona",
          mail: "infoweb@duranyduranabogados.com",
          telefono: "900 83 30 20",
          comercial: "Eulalia",
          ultimo_contacto: "11/03/2025",
          status: "activo",
          oportunidad: "364€",
          tipo: "Lead"
        },
        {
          id: 417,
          empresa: "Bufete Argudo Mila",
          contacto: "Daniel Puig",
          direccion: "Comte d'Urgell 272-274, Entlo. 2a, 08036 Barcelona",
          mail: "info@argudomila.es",
          telefono: "93 419 36 33",
          comercial: "Eulalia",
          ultimo_contacto: "11/03/2025",
          status: "activo",
          oportunidad: "587€",
          tipo: "Lead"
        }
      ];

      this.leads_data = leads_data;
      this.total_leads = leads_data?.length || 0;
      this.loading = false;
      this.closeloadingspinner();
    },

    searchConfirm(closeFn) {
      closeFn();
      // this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      // this.getDataFromApi();
    },

    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id")
      };
      console.log(model);
      return model;
    },

    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        const body = _.omit(item);
        let save_res = null;
        if (body.id) {
          save_res = await ApiService.put(`salesforce/users/${body.id}`, body);
        } else {
          save_res = await ApiService.post(`salesforce/users`, body);
        }
        if (save_res.success) {
          logInfo("Actualizada");
          this.showEdit = false;
          // this.getDataFromApi();
        } else {
          logError(save_res.message);
        }
        this.isSaving = false;
      }
    },

    close() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      console.log(this.deleteItemId);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`salesforce/users/${this.deleteItemId}`).then(() => {
          logInfo("Eliminada");
          this.getDataFromApi();
        });
      }
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    showloadingspinner() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#leads_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    closeloadingspinner() {
      this.loadingInstance.close();
    },

    onItemGo(item) {
      this.$router.push({
        name: "leads"
      });
    },

    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        this.isSelecting = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        try {
          const response = await ApiService.post(
            `salesforce/users/uploadexcel`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            this.getDataFromApi();
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
            this.getDataFromApi();
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelecting = false;
          this.isLoadingSpinner = false;
        } catch (error) {
          console.log(error);
        }
      }
    },

    init() {
      this.getDataFromApi();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style>
.v-btn {
  text-transform: capitalize;
}
table tr td .v-btn {
  margin-bottom: 0 !important;
}
.filter_row td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
td.ve-table-body-td.table-body-cell-even {
  background: #ddd !important;
}
td.ve-table-body-td.status-active {
  background: #a9ddb7 !important;
}
td.ve-table-body-td.status-inactive {
  background: #d19595 !important;
}
td.ve-table-body-td.tipo-cliente {
  background: #8accd8 !important;
}
td.ve-table-body-td.tipo-lead {
  background: #e99587 !important;
}
</style>
